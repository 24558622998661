import React from 'react';
import "../styles/exampleImages.scss"
import Image from "gatsby-image";





const ExampleImages = props => (
    <div className="exampleContainer">
        <div className="titleName">
            {props.title}
        </div>
        <div className="imgExampleContainer">
            {Object.values(props.images)[0].map((item, i) =>
                <div key={i} className="img">
                    <Image fluid={item.node.childImageSharp.fluid} />
                </div>
            )
            }
        </div>

    </div>

)

export default ExampleImages;