import React from "react"
import Image from "gatsby-image";
import "../../styles/offer.scss"
import OfferHeader from "../../components/OfferHeader";
import OfferItem from "../../components/OfferItem"
import Realizations from "../../components/Realizations"
import SEO from "../../components/seo"
import ExampleImages from "../../components/ExampleImages";
import { graphql } from "gatsby"

const dataFurrier = {
  header: "Szyjemy na wymiar kożuchy damskie i męskie, opracowujemy futra naturalne.",
  text: "Od podstaw uszyjemy kurtki skórzane, futra, a także kożuchy damskie i męskie. Nasze wyroby starannie dopasujemy do sylwetki, a także dołożymy wszelkich starań, aby spełniły wszystkie wizualne preferencje klienta.",
  link: "cennik/kusnierstwo",
  relazationDesc: "Realizujemy różnorodne prace kuśnierskie. Zapewniamy pełen profesjonalizm, nasze wieloletnie doświadczenie w branży pozwala nam wykonywać usługi solidnie, szybko i za rozsądną cenę.",
  realizationHeader: "Nasze przykładowe realizacje",
  headerItem1: "Czyścimy odzież oraz poddajemy procesom renowacji",
  descItem1: "U nas: renowacja skór, naprawa kożuchów, farbowanie zamszy, malowanie skóry, pielęgnacja futer, czyszczenie skóry,  regeneracja i naprawa foteli i mebli skórzanych. Dzięki nam Twoja odzież odzyska blask i dawną świetność.",
  headerItem2: "Zastąpimy, uszyjemy od nowa uszkodzone elementy",
  descItem2: "Świadczymy usługi w zakresie napraw i przeróbek powierzonej nam odzieży. Jesteśmy w stanie podjąć się wymianie przetartych elementów skóry, podszewek czy zepsutych zamków.",
  headerItem3: "Przerobimy i dopasujemy do Twojej sylwetki",
  descItem3: "Każdy projekt jest starannie konsultowany uwzględniając preferencje i wymagania klienta. Zajmujemy się także przeróbkami, renowacją i naprawą różnego rodzaju futer m.in z lisa, norek. Zwężymy, dopasujemy w talii, poszerzymy, naprawimy uszkodzenia, zmienimy obszycia, skrócimy oraz wydłużymy."
}

const description = "Szyjemy kożuchy, futra naturalne i skóry. Naprawa, regeneracja i czyszczenie skór. Futro z lisa i norek. Wymiana zamków w odzieży. Konin"
const keywords = ["kożuchy damskie", "futerko damskie", "futra naturalne", "kożuch męski", "renowacja skóry", "usługi kuśnierskie", "naprawa kożuchów"]
const FurrierOfferPage = ({ data }) => (
  <>
    <SEO
      title="Oferta | Kuśnierz"
      description={description}
      keywords={keywords}
    />
    <section className="offerPage container">
      <OfferHeader
        headerOffer={dataFurrier.header}
        textOffer={dataFurrier.text}
        linkOffer={dataFurrier.link}
      />
      <div className="imgContainer">
        <div className="mountain"><Image fluid={data.mountains.childImageSharp.fluid} /></div>
        <div className="vertical rightPanel">
          <div className="camera"><Image fluid={data.camera.childImageSharp.fluid} /></div>
          <div className="bag"><Image fluid={data.bag.childImageSharp.fluid} /></div>
        </div>

      </div>
      <Realizations
        header={dataFurrier.realizationHeader}
        desc={dataFurrier.relazationDesc}
        images={data.containers}
        linkPath={"galeria/kusnierstwo"}
      />
      <div className="offerItemsContainer">
        <OfferItem
          headerItem={dataFurrier.headerItem1}
          descItem={dataFurrier.descItem1}
          img={data.tools.childImageSharp.fluid}
        />
        <OfferItem
          headerItem={dataFurrier.headerItem2}
          descItem={dataFurrier.descItem2}
          img={data.scissors.childImageSharp.fluid}
          leftImg
        />
        <OfferItem
          headerItem={dataFurrier.headerItem3}
          descItem={dataFurrier.descItem3}
          img={data.tools2.childImageSharp.fluid}

        />
      </div>
      <div className="materials">
        <div className="header">Korzystamy z sprawdzonych materiałów</div>
        <div className="desc">Stosujemy materiały własne, różnego rodzaju skóry, tkaniny odzieżowe, sportowe oraz materiały powierzone przez klienta.</div>
        <ExampleImages
          images={data.ecoImages}
          title="Skóry licowe, zamszowe, ekologiczne"
        />
        <ExampleImages
          images={data.leatherImages}
          title="Skóry futerkowe, kożuchowe"
        />
        <ExampleImages
          images={data.ostrichImages}
          title="Skóra z strusia"
        />
      </div>

    </section>
  </>



)
export const query = graphql`
  {
            containers: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "furriery/realizations"}}) {
            edges {
            node {
            childImageSharp {
            fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
      }
    }
  },
  ecoImages: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, name: {regex: "/eco/"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },
  leatherImages: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, name: {regex: "/futerkowa/"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },
  ostrichImages: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, name: {regex: "/skora_strusia/"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },
    bag: file(name: {eq: "furrier_bag"}) {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
    },
    mountains: file(name: {eq: "mountains"}) {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
    },
    camera: file(name: {eq: "camera"}) {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
    }
    tools: file(name: {eq: "furrier_tools"}) {
            childImageSharp {
            fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
    }
    tools2: file(name: {eq: "furrier_tools3"}) {
            childImageSharp {
            fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
    }
    scissors: file(name: {eq: "fur_scissors"}) {
            childImageSharp {
            fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
        }
        }
    }

  }
`
export default FurrierOfferPage
